.aa-logo-text {
  text-decoration-thickness: 10px;
  color: red;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
