#schedule-page {
  ion-fab-button {
    --background: var(--ion-color-step-150, #fff);
    --background-hover: var(--ion-color-step-200, #f2f2f2);
    --background-focused: var(--ion-color-step-250, #d9d9d9);

    --color: var(--ion-color-primary, #3880ff);
  }

  /*
   * Material Design uses the ripple for activated
   * so only style the iOS activated background
   */
  .ios ion-fab-button {
    --background-activated: var(--ion-color-step-250, #d9d9d9);
  }

  ion-item-sliding.track-ionic ion-label {
    border-left: 2px solid var(--ion-color-primary);
    padding-left: 10px;
  }
  ion-item-sliding.track-angular ion-label {
    border-left: 2px solid var(--ion-color-angular);
    padding-left: 10px;
  }
  ion-item-sliding.track-communication ion-label {
    border-left: 2px solid var(--ion-color-communication);
    padding-left: 10px;
  }
  ion-item-sliding.track-tooling ion-label {
    border-left: 2px solid var(--ion-color-tooling);
    padding-left: 10px;
  }
  ion-item-sliding.track-services ion-label {
    border-left: 2px solid var(--ion-color-services);
    padding-left: 10px;
  }
  ion-item-sliding.track-design ion-label {
    border-left: 2px solid var(--ion-color-design);
    padding-left: 10px;
  }
  ion-item-sliding.track-workshop ion-label {
    border-left: 2px solid var(--ion-color-workshop);
    padding-left: 10px;
  }
  ion-item-sliding.track-food ion-label {
    border-left: 2px solid var(--ion-color-food);
    padding-left: 10px;
  }
  ion-item-sliding.track-documentation ion-label {
    border-left: 2px solid var(--ion-color-documentation);
    padding-left: 10px;
  }
  ion-item-sliding.track-navigation ion-label {
    border-left: 2px solid var(--ion-color-navigation);
    padding-left: 10px;
  }
}
